@font-face {
  font-family: 'villume-icon';
  src:  url('https://assets.villume.com/DEV/assets/icons/villume-icons-gallery/villume-icon.eot?t=v.1.2.4&uytude');
  src:  url('https://assets.villume.com/DEV/assets/icons/villume-icons-gallery/villume-icon.eot?t=v.1.2.4&uytude#iefix') format('embedded-opentype'),
    url('https://assets.villume.com/DEV/assets/icons/villume-icons-gallery/villume-icon.ttf?t=v.1.2.4&uytude') format('truetype'),
    url('https://assets.villume.com/DEV/assets/icons/villume-icons-gallery/villume-icon.woff?t=v.1.2.4&uytude') format('woff'),
    url('https://assets.villume.com/DEV/assets/icons/villume-icons-gallery/villume-icon.svg?t=v.1.2.4&uytude#villume-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.vi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'villume-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vi-speech:before {
  content: "\e900";
}
.vi-avatar:before {
  content: "\e901";
}
.vi-cube:before {
  content: "\e902";
}
.vi-silent-modify:before {
  content: "\e903";
}
.vi-min-light2:before {
  content: "\e904";
}
.vi-instagram:before {
  content: "\e905";
}
.vi-start:before {
  content: "\e906";
}
.vi-arrow-down:before {
  content: "\e907";
}
.vi-arrow-up:before {
  content: "\e908";
}
.vi-arrow-right:before {
  content: "\e909";
}
.vi-arrow-left:before {
  content: "\e90a";
}
.vi-plus:before {
  content: "\e90b";
}
.vi-delete:before {
  content: "\e90c";
}
.vi-refresh-right:before {
  content: "\e90d";
}
.vi-refresh-left:before {
  content: "\e90e";
}
.vi-maps:before {
  content: "\e90f";
}
.vi-field-of-view:before {
  content: "\e910";
}
.vi-eye:before {
  content: "\e911";
}
.vi-close-circle:before {
  content: "\e912";
}
.vi-go-back:before {
  content: "\e913";
}
.vi-eyes-closed:before {
  content: "\e914";
}
.vi-check-circle:before {
  content: "\e915";
}
.vi-prev-play:before {
  content: "\e916";
}
.vi-frame:before {
  content: "\e917";
}
.vi-global:before {
  content: "\e918";
}
.vi-next-play:before {
  content: "\e919";
}
.vi-align-top:before {
  content: "\e91a";
}
.vi-align-center:before {
  content: "\e91b";
}
.vi-align-bottom:before {
  content: "\e91c";
}
.vi-clossed-look:before {
  content: "\e91d";
}
.vi-help:before {
  content: "\e91e";
}
.vi-vartical-align-center:before {
  content: "\e91f";
}
.vi-vartical-align-left:before {
  content: "\e920";
}
.vi-vartical-align-right:before {
  content: "\e921";
}
.vi-arrow-to-top:before {
  content: "\e922";
}
.vi-arrow-break:before {
  content: "\e923";
}
.vi-arrow-to-down:before {
  content: "\e924";
}
.vi-letter-english-a:before {
  content: "\e925";
}
.vi-circle:before {
  content: "\e926";
}
.vi-help-circle:before {
  content: "\e927";
}
.vi-home:before {
  content: "\e928";
}
.vi-facebook:before {
  content: "\e929";
}
.vi-open-lock:before {
  content: "\e92a";
}
.vi-x:before {
  content: "\e92b";
}
.vi-share-to:before {
  content: "\e92c";
}
.vi-camera:before {
  content: "\e92d";
}
.vi-bars:before {
  content: "\e92e";
}
.vi-axios:before {
  content: "\e92f";
}
.vi-min-light:before {
  content: "\e930";
}
.vi-save:before {
  content: "\e931";
}
.vi-upload:before {
  content: "\e932";
}
.vi-play:before {
  content: "\e933";
}
.vi-pause:before {
  content: "\e934";
}
.vi-icon-info:before {
  content: "\e935";
}
.vi-fullscreen:before {
  content: "\e937";
}
.vi-close-fullscreen:before {
  content: "\e938";
}
