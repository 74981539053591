/*
Color
=========================== */
$color-title: #3a3a3a;
$color-content: #C0C0C0;
$color-primary: #FFDA16;
$color-primary2: rgb(18, 133, 110);
$color-danger: #CF242E;
$color-secondary: #686363;
$color-warning: #C07723;
$color-success: #378D31;
$color-info: #2A80B1;

$color-dark1: #171717;
$color-dark2: #1F1F1F;
$color-dark3: #292929;
$color-dark4: #313131;
$color-dark5: #414141;
$color-dark6: #565656;
$color-dark7: #383838;

$color-black: #000000;

$color-gray1: #979797;
$color-gray2: #C0C0C0;
$color-gray3: #E7E7E7;
$color-gray4: #9F9F9F;
$color-gray5: #A8A8A8;
$color-gray6: #C4C4C4;
$color-gray7: #D3D3D3;
$color-gray8: #F7F7F7;
$color-gray9: #692626;

$color-white: #FFFFFF;

// Media Query Ranges
// ==========================================================================
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$desktop-screen: 1440px !default;
$desktop-screen-up: 1441px !default;
$sidebar-width: 120px !default;
$sidebar-width-small: 100px !default;

/*
Bootstrap Custom
=========================== */
$theme-colors: (
    "primary": $color-primary,
    "danger": $color-danger,
    "secondary": $color-secondary,
    "warning": $color-warning,
    "success": $color-success,
    "info": $color-info
);

/*
Font Family
=========================== */
$font-suisse: 'suisse_intlregular';
$font-whyte: 'whyte_inktrapregular';
$font-size: 14px;

/*
General Extend
=========================== */
.animate {
	transition: all 0.3s ease-in-out;
}

.removeScrollStyle{
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
	
	&::-webkit-scrollbar { 
        width: 0 !important;
        height: 0 !important;
    }
}