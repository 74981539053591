.introjs {
    font-family: $font-suisse;

    &-tooltip {
        background-color: #252322;

        &.shake {
            animation: tilt-shaking 0.5s forwards;
        }
        
        &-header {
            padding: 20px 20px 0;
            position: unset;
        }

        &-title {
            color: $color-gray1;
            font-size: 14px;
            font-family: $font-suisse;
            font-weight: 400;

            @media (max-width: $small-screen) {
                font-size: 12px;
            }
        }
    }

    &-tooltiptext {
        color: $color-gray3;
        font-size: 16px;
        font-family: $font-suisse;
        padding: 0 20px 20px;

        @media (max-width: $small-screen) {
            font-size: 14px;
        }
    }

    &-tooltipbuttons {
        border: none;
        padding: 0 20px 20px;
    }

    &-button {
        transition: none;
        padding: 10px 18px;
        border-radius: 32px;
        font-size: 16px;
        line-height: 20px;
        border: none;
        text-shadow: none;

        @media (max-width: $small-screen) {
            font-size: 14px;
            line-height: 22px;
            padding: 5px 15px;
        }

        &:focus {
            box-shadow: none;
            border: none;
            background-color: $color-primary;
        }

        &:hover {
            background-color: $color-primary;
            color: $color-dark1;
        }
    }

    &-prevbutton {
        background: $color-dark6;
        color: $color-gray3;
    }

    &-nextbutton {
        background: $color-primary;
        color: $color-dark1;

        &:active {
            background-color: $color-gray4;
        }

        i {
            font-size: 14px;
            margin-left: 5px;
            font-weight: 600;
            vertical-align: middle;

            @media (max-width: $small-screen) {
                font-size: 11px;
            }
        }
    }

    &-skipbutton {
        top: unset;
        right: unset;
        bottom: 20px;
        left: 18px;
        width: 91px;
        height: 41px;
        z-index: 2;
        opacity: 0;
    }

    &-helperLayer {
        box-shadow: none !important;
        pointer-events: none;
    }

    &-arrow {
        border-width: 10px;  
        &.top {
            top: -19px;
            border-bottom-color: #252322;
        }

        &.bottom {
            bottom: -19px;
            border-top-color: #252322;
        }

        &.bottom-middle {
            border-top-color: #252322;
            bottom: -19px;
        }

        &.left {
            left: -19px;
            border-right-color: transparent;
        }

        &.right {
            right: -19px;
            border-left-color: #252322;
        }
    }

    &-overlay {
        pointer-events: none;
    }
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}