/*
Typography
=========================== */
html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-x: hidden !important;
    @extend .removeScrollStyle;
}

body {
	color: $color-gray3;
    font-family: $font-suisse !important;
    font-size: 16px;
    line-height: 136.5%;
    font-weight: normal;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #f5f5f5;
    letter-spacing: -0.01em;

    &.dark{
        background-color: #4D5559 !important;
    }
}

button{
    cursor: pointer;
}

a{
	outline:0;
    cursor: pointer;
    color: var(--color-primary);
    text-decoration: none;
    @include transition("all .5s ease-in-out");

    &:focus{
        outline: 0;
    }

    &:hover{
        color: var(--color-primary);
        text-decoration:underline;
    }

    &.link{
        color: $color-content;
        text-decoration: none;
    }

    &.link-primary{
        color: $color-primary !important;
        text-decoration: none;
    }
}

h1{
	margin: 0 0 15px 0;
	font-size: 48px;
    font-family: $font-suisse;
    line-height: 52px;
    letter-spacing: -0.01em !important;
    font-weight: 400;
    text-transform: none !important;
    color: $color-white;

    small{
        color: #bfbfbf;
    }
}

h2{
    @extend h1;
	font-size:36px;
	line-height:48px;
}

h3{
    @extend h1;
	font-size:30px;
	line-height:36px;
}

h4{
    @extend h1;
	font-size:24px;
	line-height:30px;
}

h5{
    @extend h1;
	font-size:18px;
	line-height:24px;
}

h6{
    @extend h1;
	font-size:14px;
	line-height:18px;
}

p{
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 136.5%;
    letter-spacing: -0.01em;

    &:last-child{
        margin-bottom: 0;
    }
}

label{
    font-weight: 600;
    color:  $color-title;
}

i{
    font-size: 16px;
    color:  $color-title;
}

strong{
    color: $color-title;
}
