.wrap-user{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--text-3);
    @include mobile-screen(portrait){
        font-size: 14px;
    }

    span{
        margin-right: 10px;
    }

    .img-avatar{
        width: 30px;
        height: 30px;
        background-color: blueviolet;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
    }
}

// goback button
.btn-goback{
    padding: 12px 0 6px;
    background-color: var(--bg-3);
    color: var(--text-3);
    border-radius: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 40px;

    &:hover{
        color: var(--text-3);
    }

    &:focus{
        outline: none;
        border: 0;
        box-shadow: none;
    }

    .vi{
        font-size: 16px;
        color: var(--text-3);
        font-weight: bold;
        margin-right: 5px;
    }
}

// date
.wrap-date{
    color: var(--text-2);
    margin-top: 20px;
    text-align: right;
    font-size: 14px;

    .arrow-lenght{
        margin: -3px 10px 0;
        object-fit: cover;
    }

    .vi{
        margin: 0 5px;
        font-weight: bold;
        font-size: 12px;
        color: var(--text-2);
        @include mobile-screen(portrait){
            margin: 0 3px;
        }
    }
}

.display-flex{
    display: flex;
}

.introjs-overlay {
    pointer-events: none;
}