.overlay-dialog{
    position: absolute;
    overflow: hidden;
    top: 0;
    left: -1px;
    right: 0;
    z-index: 1000;
    background: rgba(159, 159, 159, 0.5);
    height:  var(--vh);

    .btn-close{
        background: transparent;
        border: 0;
        position: fixed;
        right: 19px;
        top: 23px;
        z-index: 1005;

        @media (max-width: $small-screen) {
            top: 10px;
            right: 5px;
        }

        .vi{
            font-size: 16px;
            color: $color-gray1;
        }
    }

    .wrap-dialog{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1001;
        background-color: $color-dark3;
        transform: translate(-50%, -50%);
        width: 960px;
        max-height: calc(var(--vh) - 10px);
        overflow-y: auto;

        @media (max-width: $medium-screen) {
            width: 100%;
        }

        @media (max-width: $small-screen) {
            height: calc(var(--vh) + 2px) ;
            max-height: calc(var(--vh) + 2px);
            margin-top: -1px;
            overflow-y: auto;
        }

        .header{
            padding: 44px 21px 25px;
            position: relative;

            @media (max-width: $small-screen) {
                padding: 34px 15px 27px;
                background: $color-dark1;
            }

            .title{
                font-weight: 400;
                font-size: 40px;
                line-height: 102%;
                letter-spacing: -0.01em;
                color: $color-gray1;
                font-family: $font-whyte;

                @media (max-width: $small-screen) {
                    font-size: 30px;
                    line-height: 102%;
                }
            }
        }

        .body{
            max-width: 76%;
            margin: auto;

            @media (max-width: $small-screen) {
                max-width: 100%;
                margin-left: 0;
                padding: 0 16px;
            }    
        }
        
        .footer{
            text-align: center;
            padding: 47px 30px 55px;

            @media (max-width: $small-screen) {
                padding: 50px 0 63px;
                width: 100%;
            }

            .btn-gray{
                padding: 12px 22px 13px;
                border-radius: 40px;
                font-size: 16px;
                line-height: 94.3%;
                color: $color-dark1;
                background-color: $color-gray2;
                border: $color-gray2;

                &:focus {
                    background: $color-gray1;
                }

                @media (max-width: $small-screen) {
                    padding: 15px 25px 15px;
                    display: flex;
                    align-items: center;
                    margin: auto;
                }

                i {
                    font-size: 13px;
                    font-weight: 600;
                    margin-bottom: -3px;
                    color: $color-dark1;
                    margin-right: 4px;
                }
            }
        }
    }
}