/* You can add global styles to this file, and also import other style files */
// Font
//============================
@import './assets/scss/common/fonts';

@import './assets/scss/common/mixins';
@import './assets/scss/common/variables';

@import "./assets/scss/common/icons";

// Asset General
// ===============================
@import './assets/scss/common/helper';
@import './assets/scss/common/typography';
@import './assets/scss/common/viewer';
@import './assets/scss/common/button';
@import './assets/scss/common/dialog';
@import './assets/scss/common/introJsTooltip';