.btn{
    border: 0;
    height: 40px;
    font-size: 14px;
    white-space: nowrap;
    padding: 8px 27.5px;
    position: relative;

    &.btn-outline-primary{
        border: solid 1px var(--text-1);
        border-radius: 30px;
        background-color: var(--bg-4);
    }
}

// loading button
.loading-button{
    color: transparent !important;
    pointer-events: none;
    
    &::after{
        content: '';
        display: block;
        width: 1.2em;
        height: 1.2em;
        position: absolute;
        top: 21%;
        border: 0.15em solid transparent;
        border-right-color: $color-gray1;
        border-bottom-color: $color-gray1;
        border-radius: 50%;
        animation: button-anim 0.7s linear infinite;
        opacity: 1;
    }

    @keyframes button-anim {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
}