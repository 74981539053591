// ANCHOR merriweather fonts
// #region
@font-face {
	font-family: 'merriweatherregular';
	src: url('https://assets.villume.com/DEV/assets/fonts/merriweather/merriweather-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
		url('https://assets.villume.com/DEV/assets/fonts/merriweather/merriweather-regular-webfont.woff?t=v.1.2.4') format('woff');
	font-weight: normal;
	font-style: normal;
}
// #endregion

// ANCHOR suisse_intl fonts
// #region
@font-face {
	font-family: 'suisse_intlmedium';
	src: url('https://assets.villume.com/DEV/assets/fonts/suisse-inti/suisse_intl_medium-webfont.woff2?t=v.1.2.4') format('woff2'),
		url('https://assets.villume.com/DEV/assets/fonts/suisse-inti/suisse_intl_medium-webfont.woff?t=v.1.2.4') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'suisse_intlregular';
	src: url('https://assets.villume.com/DEV/assets/fonts/suisse-inti/suisseintl-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
		url('https://assets.villume.com/DEV/assets/fonts/suisse-inti/suisseintl-regular-webfont.woff?t=v.1.2.4') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
// #endregion

// ANCHOR whyteinktrap fonts
// #region
@font-face {
	font-family: 'whyte_inktrapbold';
	src: url('https://assets.villume.com/DEV/assets/fonts/whyteinktrap/whyteinktrap-bold-webfont.woff2?t=v.1.2.4') format('woff2'),
		url('https://assets.villume.com/DEV/assets/fonts/whyteinktrap/whyteinktrap-bold-webfont.woff?t=v.1.2.4') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'whyte_inktrapregular';
	src: url('https://assets.villume.com/DEV/assets/fonts/whyteinktrap/whyteinktrap-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
		url('https://assets.villume.com/DEV/assets/fonts/whyteinktrap/whyteinktrap-regular-webfont.woff?t=v.1.2.4') format('woff');
	font-weight: normal;
	font-style: normal;
	border: solid 1px white;
	font-display: swap;
}
// #endregion

// ANCHOR Text Wall fonts pack1
// #region
@font-face {
    font-family: 'oxygenregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/oxygen-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/oxygen-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/roboto-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/roboto-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'soraregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/sora-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/sora-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hahmletregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/hahmlet-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/hahmlet-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'jetbrains_monoregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/jetbrainsmono-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/jetbrainsmono-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'andada_proregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/andadapro-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/andadapro-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'epilogueregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/epilogue-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/epilogue-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'interregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/inter-variablefont_slntwght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/inter-variablefont_slntwght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'interregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/inter-variablefont_slntwght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/inter-variablefont_slntwght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'encode_sanscondensed_thin';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/encodesans-variablefont_wdthwght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/encodesans-variablefont_wdthwght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'manropeextralight';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/manrope-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/manrope-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'loraregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-1/lora-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-1/lora-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}
//#endregion

// ANCHOR Text Wall fonts pack2
// #region
@font-face {
    font-family: 'biorhymeextrabold';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/biorhyme-variablefont_wdthwght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/biorhyme-variablefont_wdthwght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'playfair_displayregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/playfairdisplay-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/playfairdisplay-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'archivosemibold';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/archivo-variablefont_wdthwght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/archivo-variablefont_wdthwght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cormorantlight';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/cormorant-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/cormorant-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'spectralregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/spectral-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/spectral-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'spectralregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/spectral-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/spectral-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ralewaythin';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/raleway-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/raleway-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sansregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/worksans-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/worksans-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/lato-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/lato-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'antonregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/anton-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/anton-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'old_standard_ttregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/oldstandardtt-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/oldstandardtt-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'oswaldregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-2/oswald-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-2/oswald-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}
//#endregion

// ANCHOR Text Wall fonts pack3
// #region
@font-face {
    font-family: 'montserratthin';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/montserrat-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/montserrat-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/poppins-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/poppins-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitoextralight';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/nunito-variablefont_wght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/nunito-variablefont_wght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/opensans-variablefont_wdthwght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/opensans-variablefont_wdthwght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'space_monoregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/spacemono-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/spacemono-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ibm_plex_sansregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/ibmplexsans-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/ibmplexsans-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inconsolataregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/inconsolata-variablefont_wdthwght-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/inconsolata-variablefont_wdthwght-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'quattrocentoregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/quattrocento-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/quattrocento-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'times_new_roman_cyrregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/times_new_roman-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/times_new_roman-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arial_narrowregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/arialn-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/arialn-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'source_sans_proregular';
    src: url('https://assets.villume.com/DEV/assets/fonts/pack-3/sourcesanspro-regular-webfont.woff2?t=v.1.2.4') format('woff2'),
         url('https://assets.villume.com/DEV/assets/fonts/pack-3/sourcesanspro-regular-webfont.woff?t=v.1.2.4') format('woff');
    font-weight: normal;
    font-style: normal;
}
// #endregion
